export default [
  {
    key: "idtransaction",
    label: "ID Transaction",
  },
  {
    key: "client_name",
    label: "Client",
  },
  {
    key: "amount",
    label: "Amount",
  },
  {
    key: "card_number",
    label: "Credit card",
  },
  {
    key: "advisor_name",
    label: "Advisor",
  },
  {
    key: "value",
    label: "Program",
  },
  {
    key: "responsable",
    label: "Responsible",
  },
  {
    key: "paid",
    label: "Paid",
  },
  {
    key: "actions",
    label: "Actions",
  },
]
