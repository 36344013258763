<template>
  <div>
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['chargeBack'].refresh()"
      @reset-all-filters="resetAllFilters"
    >
      <template #table>
        <b-table
          id="charge-back"
          ref="chargeBack"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          :items="chargeBackProvider"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(client_name)="data">
            <span v-if="module === 5">
              <router-link
                :to="{
                  name: 'debt-solution-dashboard',
                  params: {
                    idClient: data.item.client_account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}</router-link
              >
            </span>
            <span v-else-if="module === 3">
              <router-link
                :to="{
                  name: 'bussiness-dashboard-clients',
                  params: {
                    idClient: data.item.client_account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}</router-link
              >
            </span>
            <span v-else-if="module === 4">
              <router-link
                target="_blank"
                :to="{
                  path:
                    '/administration/clients/account' +
                    '/' +
                    data.item.client_account_id,
                }"
              >
                {{ data.item.client_name }}
              </router-link>
            </span>
            <span v-else-if="module === 6">
              <router-link
                :to="{
                  name: 'credit-experts-dashboard',
                  params: {
                    idClient: data.item.client_account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}</router-link
              >
            </span>
            <span v-else-if="module === 7">
              <router-link
                :to="{
                  name: 'boost-credit-dashboard',
                  params: {
                    idClient: data.item.client_account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}</router-link
              >
            </span>
            <span v-else-if="module === 8">
              <router-link
                :to="{
                  name: 'tax-research-client-dashboard',
                  params: {
                    idClient: data.item.client_account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}</router-link
              >
            </span>
            <span v-else-if="module === 10">
              <router-link
                :class="[textLink]"
                :to="{
                  name: 'claim-department-dashboard',
                  params: {
                    idClient: data.item.client_account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}</router-link
              >
            </span>
            <span v-else-if="module === 14">
              <router-link
                :class="[textLink]"
                :to="{
                  name: 'bookeeping-dashboard',
                  params: {
                    idClient: data.item.client_account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}</router-link
              >
            </span>

            <!--         <span v-else-if="module === 4">
              <router-link
                :class="[textLink]"
                :to="{
                  name: 'administration-dashboard',
                  params: {
                    idClient: data.item.client_account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}</router-link
              >
            </span> -->

            <span v-else :class="textLink">
              {{ data.item.client_name }}
            </span>
          </template>
          <template #cell(amount)="data">
            $ {{ data.item.amount | currency }}
          </template>
          <template #cell(paid)="data">
            <span v-if="data.item.responsable === 'ADVISOR'">{{
              data.item.paid ? "PAID" : "PENDING"
            }}</span>
          </template>
          <template #cell(actions)="data">
            <feather-icon
              class="mr-1 pointer text-primary"
              icon="EyeIcon"
              size="18"
              @click="openTrackingModal(data.item.id)"
            />
            <tabler-icon
              class="mr-1 pointer text-danger"
              v-if="showForwardIcon(data.item.n_status) && metaStatus === 1"
              icon="ArrowForwardUpIcon"
              size="18"
              @click="openAddEvidencesModal(data.item)"
            />
            <feather-icon
              v-if="
                data.item.responsable === 'ADVISOR' &&
                !data.item.paid &&
                module === 16 &&
                metaStatus === 2
              "
              class="mr-1 pointer text-success"
              icon="CheckIcon"
              size="18"
              @click="payChargeBack(data.item.id)"
            />
          </template>
        </b-table>
        <charge-back-tracking-modal
          v-if="openTrackingModalController"
          @close="closeTrackingModal"
          :chargeBackId="chargeBackId"
        />
        <add-evidences-modal
          v-if="openAddEvidencesController"
          @close="closeAddEvidencesModal"
          :itemToEvidence="itemToEvidence"
          @reload="$refs['chargeBack'].refresh()"
        />
      </template>
    </filter-slot>
  </div>
</template>
<script>
import ChargeBackService from "@/views/commons/components/charge-back/charge-back.service";
import FiltersData from "@/views/commons/components/charge-back/charge-back-module/charge-back-general/filters.data";
import ChargeBackTrackingModal from "@/views/commons/components/charge-back/charge-back-module/modals/ChargeBackTrackingModal";
import AddEvidencesModal from "@/views/commons/components/charge-back/charge-back-module/modals/AddEvidencesModal";
import inProcessFields from "@/views/commons/components/charge-back/charge-back-module/charge-back-general/inProcessFields";
import approvedFields from "@/views/commons/components/charge-back/charge-back-module/charge-back-general/approvedFields";

export default {
  components: {
    ChargeBackTrackingModal,
    AddEvidencesModal,
  },
  data() {
    return {
      openTrackingModalController: false,
      openAddEvidencesController: false,
      metaStatus: "",
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      filter: FiltersData,
      filter2: [],
      fields: [],
      items: [],
      totalRows: 0,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: "amount",
      sortDesc: true,
      searchInput: "",
      chargeBackId: null,
      advisorResponsible: false,
      module: "",
      itemToEvidence: [],
      program: {
        type: "select",
        margin: true,
        showLabel: true,
        label: "Program",
        model: null,
        options: [],
        reduce: "id",
        selectText: "value",
        cols: 12,
      },
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  created() {
    this.metaStatus = this.$route.meta.status;
    this.module = this.moduleId;
  },
  async mounted() {
    this.filter[2].options = [
      { id: null, label: "ALL" },
      { id: 1, label: "AMG" },
      { id: 2, label: "ADVISOR" },
    ];
    this.filter[3].options = [
      { id: 0, label: "ALL" },
      { id: 1, label: "IN MANAGEMENT" },
      { id: 2, label: "IN ADMINISTRATION" },
      { id: 3, label: "IN PROGRAM" },
    ];
    if (this.module === 16 || this.module === 4) {
      if (this.filter.length === 4) {
        this.filter.push(this.program);
      }

      this.filter[4].options = await ChargeBackService.getPrograms();
    }
  },
  methods: {
    async payChargeBack(id) {
      const response = await this.showConfirmSwal();
      if (response.isConfirmed) {
        const data = await ChargeBackService.setPaid({
          chargeback_id: id,
        });
        if (data.status === 200) {
          this.$refs["chargeBack"].refresh();
        }
      }
    },
    showForwardIcon(n_status) {
      return (
        (this.module == 16 && n_status == 1) ||
        (this.module == 4 && n_status == 2) ||
        (this.module != 4 && this.module != 16 && n_status == 3)
      );
    },
    openTrackingModal(id) {
      this.chargeBackId = id;
      this.openTrackingModalController = true;
    },
    closeTrackingModal() {
      this.openTrackingModalController = false;
    },
    openAddEvidencesModal(item) {
      this.itemToEvidence = item;
      this.openAddEvidencesController = true;
    },
    closeAddEvidencesModal() {
      this.openAddEvidencesController = false;
    },
    programValue() {
      if (this.module === 16 || this.module === 4) {
        return this.filter[4].model;
      } else return "";
    },
    moduleValue() {
      if (this.module === 16 || this.module === 11 || this.module === 4) {
        return null;
      } else return this.module;
    },
    async chargeBackProvider(ctx) {
      const params = {
        orderby: 1,
        order: ctx.sortDesc === 1 ? "asc" : "desc",
        campo: this.filterPrincipal.model,
        from: this.filter[0].model,
        to: this.filter[1].model,
        modul: this.moduleValue(),
        program: this.programValue(),
        status: this.filter[3].model,
        page: ctx.currentPage,
        responsable: this.filter[2].model,
        per_page: ctx.perPage,
      };
      let data = "";
      this.fields = [];
      if (this.metaStatus === 1) {
        this.fields = inProcessFields;
        data = await ChargeBackService.chargeBackSearchInProcess(params);
      } else {
        this.fields = approvedFields;
        data = await ChargeBackService.chargeBackSearchApproved(params);
      }
      this.items = data.data;
      const predicate = (element) => element.responsable === "ADVISOR";
      this.advisorResponsible = this.items.some(predicate);
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.nextPage = this.startPage + 1;
      this.endPage = data.last_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      return this.items || [];
    },
    resetAllFilters() {
      this.filter.forEach((filter) => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
      this.$refs.chargeBack.refresh();
    },
  },
};
</script>

<style scoped>
.bigger {
  width: 1.35em !important;
  height: 1.35em !important;
}
.pointer {
  cursor: pointer;
}
</style>
