<template>
  <b-modal
    v-model="onControl"
    title="Tracking"
    title-tag="h3"
    modal-class="modal-primary"
    @hidden="close"
    size="xl"
    hide-footer
  >
    <b-table
      id="tracking-list"
      ref="trackingList"
      no-border-collapse
      class="position-relative"
      :fields="fields"
      show-empty
      no-provider-filtering
      sticky-header="50vh"
      primary-key="id"
      responsive="sm"
      :items="trackingData"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(file)="data">
        <div class="d-flex justify-content-center">
          <p v-for="f in data.item.files" :key="f.id">
            <a :href="f.file" target="_blank" :title="f.file_name">
              <amg-icon
                v-if="f.extension === 'docx'"
                class="cursor-pointer text-primary"
                icon="FileWordIcon"
                size="18"
              />
              <amg-icon
                v-else-if="f.extension === 'pdf'"
                class="cursor-pointer text-danger"
                icon="FilePdfIcon"
                size="18"
              />
              <amg-icon
                v-else-if="f.extension === 'xlsx'"
                class="cursor-pointer text-success"
                icon="FileExcelIcon"
                size="18"
              />
              <amg-icon
                v-else-if="f.extension === 'pptx' || f.extension === 'ppt'"
                class="cursor-pointer text-warning"
                icon="FilePowerpointIcon"
                size="18"
              />
              <feather-icon
                v-else-if="f.extension === 'jpg' || f.extension === 'png'"
                class="cursor-pointer text-info"
                icon="ImageIcon"
                size="18"
              />
              <tabler-icon
                v-else
                class="cursor-pointer text-dark"
                icon="FileDownloadIcon"
                size="18"
              />
            </a>
          </p>
        </div>
      </template>
      <template #cell(description)="data">
        {{ formatUndefined(data.item.description) }}
      </template>
      <template #cell(createdBy)="data">
        {{ data.item.user_name }} {{ data.item.created_at | myGlobalWithHour }}
      </template>
      <template #cell(result)="data">
        <span :class="defineColor(data.item.n_result)">{{
          data.item.result
        }}</span>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import ChargeBackService from "@/views/commons/components/charge-back/charge-back.service";

export default {
  props: {
    chargeBackId: null,
  },
  data() {
    return {
      onControl: false,
      trackingData: [],
      fields: [
        {
          key: "description",
          label: "Description",
          thStyle: {
            width: "600px",
          },
        },
        {
          key: "file",
          label: "Files",
          thStyle: {
            textAlign: "center",
          },
        },
        {
          key: "responsable",
          label: "Responsible",
        },
        {
          key: "result",
          label: "Result",
        },
        {
          key: "createdBy",
          label: "Created by",
        },
      ],
      isBusy: false,
    };
  },
  async created() {
    this.onControl = true;
    await this.getTrackingData();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    formatUndefined(word) {
      if (word === "undefined") {
        return " ";
      }
      return word;
    },
    defineColor(number) {
      if (number === 1) {
        return "text-warning";
      } else if (number === 2) {
        return "text-success";
      } else {
        return "text-danger";
      }
    },
    defineFileIcon(extension) {
      if (extension === "pdf") {
        return "FilePdfIcon";
      } else if (extension === "docx") {
        return "FileWordIcon";
      } else if (extension === "pptx") {
        return "FilePowerpointIcon";
      } else if (extension === "xlsx") {
        return "FileExcelIcon";
      } else if (extension === "jpg") {
        return "ImageIcon";
      } else {
        return "FileDownloadIcon";
      }
    },
    async getTrackingData() {
      this.isBusy = true;
      const data = await ChargeBackService.getTrackingChargeBack({
        idchargeback: this.chargeBackId,
      });
      this.trackingData = data.data;
      this.isBusy = false;
    },
  },
};
</script>

<style scoped>
.column-width {
  width: 400px !important;
}
.bigger {
  width: 1.5em;
  height: 1.5em;
}
</style>
