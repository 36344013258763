<template>
  <b-modal
    v-model="onControl"
    title="Add Evidences"
    title-tag="h3"
    modal-class="modal-primary"
    size="lg"
    @hidden="close"
    no-close-on-backdrop
  >
    <drag-and-drop v-model="files" :files-array="files" :files-to-show="[]" />
    <b-form-group id="input-group-2" label="Description:" label-for="input-2">
      <b-form-textarea
        v-model="description"
        id="input-2"
        type="text"
        required
      ></b-form-textarea>
    </b-form-group>
    <b-form-group
      id="input-group-3"
      v-if="moduleId === 4 && itemLocal.tracking_counter > 1"
      label-for="input-3"
      label="Responsible:"
    >
      <v-select
        v-model="itemLocal.responsable"
        :options="responsables"
      ></v-select>
    </b-form-group>
    <validation-observer ref="result">
      <validation-provider
        v-slot="{ errors }"
        name="result"
        rules="required"
        v-if="
          moduleId === 16 &&
          (itemLocal.responsable !== null || itemLocal.responsable !== '')
        "
      >
        <b-form-group id="input-group-4" label-for="input-4" label="Result:">
          <v-select
            v-model="result"
            :reduce="(label) => label.id"
            label="label"
            :options="results"
          ></v-select>
          <span v-if="errors[0]" class="text-danger">
            Result {{ errors[0] }}</span
          >
        </b-form-group>
      </validation-provider>
    </validation-observer>
    <template #modal-footer>
      <b-button
        v-if="
          itemLocal.n_status === 2 &&
          (itemLocal.responsable !== '' || itemLocal.responsable !== null) &&
          itemLocal.tracking_counter !== 1
        "
        @click="uploadFiles(1)"
      >
        <span>Send to management</span>
      </b-button>
      <b-button
        v-if="itemLocal.n_status === 3 || itemLocal.n_status === 1"
        @click="uploadFiles(2)"
      >
        <span v-if="result === 1">Send to administration</span>
        <span v-else-if="result === 2">Approved Chargeback</span>
        <span v-else-if="result === 3">Disapproved Chargeback</span>
        <span v-else>Send to administration</span>
      </b-button>
      <b-button
        v-if="
          itemLocal.n_status === 2 &&
          (itemLocal.responsable === '' || itemLocal.responsable == null) &&
          itemLocal.tracking_counter === 1
        "
        @click="uploadFiles(3)"
      >
        <span>Send to program</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import DragAndDrop from "@/views/commons/utilities/DragAndDrop";
import vSelect from "vue-select";
import { mapGetters, mapActions, mapState } from "vuex";
import ChargeBackService from "@/views/commons/components/charge-back/charge-back.service";

export default {
  components: {
    DragAndDrop,
    vSelect,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  props: {
    itemToEvidence: null,
  },
  async created() {
    this.onControl = true;
    this.itemLocal = { ...this.itemToEvidence };
    this.responsables = [
      { id: "", label: "No one" },
      { id: 1, label: "AMG" },
      { id: 2, label: "ADVISOR" },
    ];
    this.results = [
      { id: 1, label: "Neither of them" },
      { id: 2, label: "Approved" },
      { id: 3, label: "Disapproved" },
    ];
  },
  data() {
    return {
      files: [],
      description: "",
      onControl: false,
      itemLocal: [],
      responsables: [],
      results: [],
      result: "",
    };
  },
  methods: {
    ...mapActions({
      A_GET_NOTIFICATIONS_CHARGE_BACKS:
        "ChargeBackStore/A_GET_NOTIFICATIONS_CHARGE_BACKS",
    }),
    close() {
      this.files = [];
      this.$emit("close");
    },
    async uploadFiles(sendTo) {
      try {
        const res = await this.$refs.result.validate();
        if (res) {
          const formData = new FormData();
          this.files.forEach((file) => {
            formData.append("images[]", file, file.name);
          });
          this.addPreloader();
          formData.append("module_id", this.moduleId);
          formData.append("user_id", this.currentUser.user_id);
          formData.append("description", this.description);
          formData.append("sendto", sendTo);
          formData.append("idchargeback", this.itemLocal.id);
          formData.append("client_name", this.itemLocal.client_name);
          formData.append("responsable", this.itemLocal.responsable);
          if (this.result === 1) {
            this.result = "";
          }
          formData.append("result", this.result);
          formData.append("idtransaction", this.itemLocal.idtransaction);
          const headers = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            }.bind(this),
          };
          const data = await ChargeBackService.postManAEvidences(
            formData,
            headers
          );
          if (data.status === 200) {
            this.removePreloader();
            this.$emit("reload");
            this.close();
            this.showSuccessSwal();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        this.removePreloader();
      }
    },
  },
};
</script>
