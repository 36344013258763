var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{attrs:{"filter":_vm.filter,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":function($event){return _vm.$refs['chargeBack'].refresh()},"reset-all-filters":_vm.resetAllFilters},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"chargeBack",staticClass:"position-relative",attrs:{"id":"charge-back","no-border-collapse":"","fields":_vm.fields,"show-empty":"","no-provider-filtering":"","sticky-header":"50vh","primary-key":"id","table-class":"text-nowrap","responsive":"sm","items":_vm.chargeBackProvider,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage,"filter":_vm.searchInput,"busy":_vm.isBusy},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(client_name)",fn:function(data){return [(_vm.module === 5)?_c('span',[_c('router-link',{attrs:{"to":{
                name: 'debt-solution-dashboard',
                params: {
                  idClient: data.item.client_account_id,
                },
              },"target":"_blank"}},[_vm._v(_vm._s(data.item.client_name))])],1):(_vm.module === 3)?_c('span',[_c('router-link',{attrs:{"to":{
                name: 'bussiness-dashboard-clients',
                params: {
                  idClient: data.item.client_account_id,
                },
              },"target":"_blank"}},[_vm._v(_vm._s(data.item.client_name))])],1):(_vm.module === 4)?_c('span',[_c('router-link',{attrs:{"target":"_blank","to":{
                path:
                  '/administration/clients/account' +
                  '/' +
                  data.item.client_account_id,
              }}},[_vm._v(" "+_vm._s(data.item.client_name)+" ")])],1):(_vm.module === 6)?_c('span',[_c('router-link',{attrs:{"to":{
                name: 'credit-experts-dashboard',
                params: {
                  idClient: data.item.client_account_id,
                },
              },"target":"_blank"}},[_vm._v(_vm._s(data.item.client_name))])],1):(_vm.module === 7)?_c('span',[_c('router-link',{attrs:{"to":{
                name: 'boost-credit-dashboard',
                params: {
                  idClient: data.item.client_account_id,
                },
              },"target":"_blank"}},[_vm._v(_vm._s(data.item.client_name))])],1):(_vm.module === 8)?_c('span',[_c('router-link',{attrs:{"to":{
                name: 'tax-research-client-dashboard',
                params: {
                  idClient: data.item.client_account_id,
                },
              },"target":"_blank"}},[_vm._v(_vm._s(data.item.client_name))])],1):(_vm.module === 10)?_c('span',[_c('router-link',{class:[_vm.textLink],attrs:{"to":{
                name: 'claim-department-dashboard',
                params: {
                  idClient: data.item.client_account_id,
                },
              },"target":"_blank"}},[_vm._v(_vm._s(data.item.client_name))])],1):(_vm.module === 14)?_c('span',[_c('router-link',{class:[_vm.textLink],attrs:{"to":{
                name: 'bookeeping-dashboard',
                params: {
                  idClient: data.item.client_account_id,
                },
              },"target":"_blank"}},[_vm._v(_vm._s(data.item.client_name))])],1):_c('span',{class:_vm.textLink},[_vm._v(" "+_vm._s(data.item.client_name)+" ")])]}},{key:"cell(amount)",fn:function(data){return [_vm._v(" $ "+_vm._s(_vm._f("currency")(data.item.amount))+" ")]}},{key:"cell(paid)",fn:function(data){return [(data.item.responsable === 'ADVISOR')?_c('span',[_vm._v(_vm._s(data.item.paid ? "PAID" : "PENDING"))]):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [_c('feather-icon',{staticClass:"mr-1 pointer text-primary",attrs:{"icon":"EyeIcon","size":"18"},on:{"click":function($event){return _vm.openTrackingModal(data.item.id)}}}),(_vm.showForwardIcon(data.item.n_status) && _vm.metaStatus === 1)?_c('tabler-icon',{staticClass:"mr-1 pointer text-danger",attrs:{"icon":"ArrowForwardUpIcon","size":"18"},on:{"click":function($event){return _vm.openAddEvidencesModal(data.item)}}}):_vm._e(),(
              data.item.responsable === 'ADVISOR' &&
              !data.item.paid &&
              _vm.module === 16 &&
              _vm.metaStatus === 2
            )?_c('feather-icon',{staticClass:"mr-1 pointer text-success",attrs:{"icon":"CheckIcon","size":"18"},on:{"click":function($event){return _vm.payChargeBack(data.item.id)}}}):_vm._e()]}}])}),(_vm.openTrackingModalController)?_c('charge-back-tracking-modal',{attrs:{"chargeBackId":_vm.chargeBackId},on:{"close":_vm.closeTrackingModal}}):_vm._e(),(_vm.openAddEvidencesController)?_c('add-evidences-modal',{attrs:{"itemToEvidence":_vm.itemToEvidence},on:{"close":_vm.closeAddEvidencesModal,"reload":function($event){return _vm.$refs['chargeBack'].refresh()}}}):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }