var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"Add Evidences","title-tag":"h3","modal-class":"modal-primary","size":"lg","no-close-on-backdrop":""},on:{"hidden":_vm.close},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [(
        _vm.itemLocal.n_status === 2 &&
        (_vm.itemLocal.responsable !== '' || _vm.itemLocal.responsable !== null) &&
        _vm.itemLocal.tracking_counter !== 1
      )?_c('b-button',{on:{"click":function($event){return _vm.uploadFiles(1)}}},[_c('span',[_vm._v("Send to management")])]):_vm._e(),(_vm.itemLocal.n_status === 3 || _vm.itemLocal.n_status === 1)?_c('b-button',{on:{"click":function($event){return _vm.uploadFiles(2)}}},[(_vm.result === 1)?_c('span',[_vm._v("Send to administration")]):(_vm.result === 2)?_c('span',[_vm._v("Approved Chargeback")]):(_vm.result === 3)?_c('span',[_vm._v("Disapproved Chargeback")]):_c('span',[_vm._v("Send to administration")])]):_vm._e(),(
        _vm.itemLocal.n_status === 2 &&
        (_vm.itemLocal.responsable === '' || _vm.itemLocal.responsable == null) &&
        _vm.itemLocal.tracking_counter === 1
      )?_c('b-button',{on:{"click":function($event){return _vm.uploadFiles(3)}}},[_c('span',[_vm._v("Send to program")])]):_vm._e()]},proxy:true}]),model:{value:(_vm.onControl),callback:function ($$v) {_vm.onControl=$$v},expression:"onControl"}},[_c('drag-and-drop',{attrs:{"files-array":_vm.files,"files-to-show":[]},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),_c('b-form-group',{attrs:{"id":"input-group-2","label":"Description:","label-for":"input-2"}},[_c('b-form-textarea',{attrs:{"id":"input-2","type":"text","required":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),(_vm.moduleId === 4 && _vm.itemLocal.tracking_counter > 1)?_c('b-form-group',{attrs:{"id":"input-group-3","label-for":"input-3","label":"Responsible:"}},[_c('v-select',{attrs:{"options":_vm.responsables},model:{value:(_vm.itemLocal.responsable),callback:function ($$v) {_vm.$set(_vm.itemLocal, "responsable", $$v)},expression:"itemLocal.responsable"}})],1):_vm._e(),_c('validation-observer',{ref:"result"},[(
        _vm.moduleId === 16 &&
        (_vm.itemLocal.responsable !== null || _vm.itemLocal.responsable !== '')
      )?_c('validation-provider',{attrs:{"name":"result","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"input-group-4","label-for":"input-4","label":"Result:"}},[_c('v-select',{attrs:{"reduce":function (label) { return label.id; },"label":"label","options":_vm.results},model:{value:(_vm.result),callback:function ($$v) {_vm.result=$$v},expression:"result"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" Result "+_vm._s(errors[0]))]):_vm._e()],1)]}}],null,false,3600751302)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }